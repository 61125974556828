<template>
  <div class="page_help_center right_connent">
    <div class="title">帮助中心</div>
    <div class="title_wrap">
      {{ data.feedback_name }}
    </div>
    <div class="connent_wrap">
      <span>提示:</span>
      {{ data.content }}
    </div>
    <div class="rote">
      <span>反馈:</span>
      <el-rate
        v-model="value"
        v-preventReClick
        show-text
        @change="change"
        :disabled="disabled"
      >
      </el-rate>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      value: null,
      disabled: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let { help_data } = this.$store.state;
      this.data = help_data;
    },
    // handleSupport(data) {
    //   this.support = data;
    //   uni.showToast({
    //     title: "提交成功，感谢你的评价",
    //     icon: "none",
    //   });
    // },
    change(e) {
      // console.log(e);
      if (e <= 3) {
        this.$notify({
          title: "反馈成功",
          message: "抱歉,我们将完善功能 (,,•́ . •̀,,)",
          type: "success",
        });
        this.disabled = true;
      } else if (e > 3) {
        this.$notify({
          title: "反馈成功",
          message: "感谢您的反馈我们会更加努力 ❛‿˂̵✧",
          type: "success",
        });
        this.disabled = true;
      }
  
    },
  },
  component() {},
};
</script>

<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.page_help_center {
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 35px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 30px;
  }
  .title_wrap {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
  }
  .connent_wrap {
    margin-bottom: 50px;
    span {
      color: #479dff;
    }
    padding-top: 50px;
    font-size: 16px;
    
  }
  .rote {
    display: flex;
    align-items: center;
    span {
      color: #479dff;
    }
  }
}
</style>
